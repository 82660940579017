<template>
  <div class="">
    <div class="subhead-text">{{ form_type_description(form_type) }}</div>
    <error-banner
      v-if="showErrors"
      :messages="errorMsgs"
      :info="errorMsgs.length === 0"
      :error="errorMsgs.length > 0"
      class="mb-5"
    >
      <template v-slot:title>
        <div
          :class="{
            'font-semibold text-lg': true,
            'mb-3 pb-1 border-b': 0 !== errorMsgs.length,
          }"
        >
          <div v-if="errorMsgs.length > 0">
            Missing values for the following fields
          </div>
          <div v-else>Profile Data Valid</div>
        </div>
      </template>
      <template v-slot:body>
        <div class="flex items-center justify-start" v-if="validating">
          <div><font-awesome-icon icon="spinner" spin /></div>
        </div>
      </template>
    </error-banner>
    <div class="grid grid-cols-3 gap-6">
      <form
        @submit.prevent
        class="box-style col-start-1 row-start-1 row-span-3 col-span-3 lg:col-span-2"
      >
        <div v-for="(field, idx) in form_fields" :key="idx">
          <div
            v-if="!field.parent_field_id"
            :class="setBorder(field.border_present)"
          >
            <div class="flex">
              <div
                v-if="field.type.toLowerCase() !== 'checkbox'"
                class="thirdhead-text"
                :class="field.label !== 'Date' ? 'pt-3' : ''"
              >
                {{ field.name }}
              </div>
              <div v-if="'info' in field" class="relative pt-4 pl-2 group">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                >
                  <path
                    d="M-2833-2335a7,7,0,0,1,7-7,7,7,0,0,1,7,7,7,7,0,0,1-7,7A7,7,0,0,1-2833-2335Zm1.355,0a5.651,5.651,0,0,0,5.645,5.645,5.652,5.652,0,0,0,5.646-5.645,5.652,5.652,0,0,0-5.646-5.646A5.651,5.651,0,0,0-2831.645-2335Zm4.259,4v-.36c.744-.084.828-.144.828-1.008v-2.436c0-.779-.072-.828-.708-.923v-.337a11.105,11.105,0,0,0,2.029-.455v4.152c0,.84.059.912.828,1.008v.36Zm.636-7.164a.773.773,0,0,1,.768-.768.758.758,0,0,1,.768.768.766.766,0,0,1-.779.756A.777.777,0,0,1-2826.75-2338.164Z"
                    transform="translate(2833 2342)"
                    fill="#510d76"
                  />
                </svg>
                <Info
                  :info_header="field.info.header"
                  :info_text="field.info.text"
                  class="invisible group-hover:visible"
                />
              </div>
            </div>
            <div>{{ field.description }}</div>
            <RenderField
              :type="field.type.toLowerCase()"
              :field_id="field.field_id"
              :value="values_map ? values_map[field.field_id] : null"
              :value_map="values_map"
              :options="
                'options' in field
                  ? field.name === 'Mode of Experience'
                    ? form_data['Mentor Present'] === 'No' &&
                      form_type === 'experience'
                      ? ['In-Person', 'Video Conference', 'Phone Conference']
                      : field.options
                    : field.options
                  : null
              "
              :hidden_content="
                'options' in field && 'hidden_content' in field
                  ? field.hidden_content
                  : null
              "
              :label="field.name"
              :child_fields="
                'child_fields' in field ? field.child_fields : null
              "
              :categories="'categories' in field ? field.categories : null"
              :category_label="
                'categories_label' in field ? field.categories_label : null
              "
              :multi_select="
                'multi_select' in field ? field.multi_select : null
              "
              :error_message="
                'error_message' in field ? field.error_message : null
              "
              :min_length="
                'min_length' in field ? parseInt(field.min_length) : null
              "
              :max_length="
                'max_length' in field ? parseInt(field.max_length) : null
              "
              :prep_message="
                'prep_message' in field ? field.prep_message : null
              "
              @emitNewInput="pushNewInput"
              @emitNewHiddenInput="pushNewInput"
            />
          </div>
        </div>
        <div v-if="is_private" class="border-t border-cool-gray">
          <div class="py-3">
            <div class="flex">
              <div class="thirdhead-text pb-2">
                <span class="font-bold">
                  <font-awesome-icon
                    class="text-dark-purple"
                    icon="eye-slash"
                  />
                </span>
                Private:
              </div>
              <div class="pt-1 pl-2 group relative">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                >
                  <!-- TODO color values needs to be parameterized or made a css var -->
                  <path
                    d="M-2833-2335a7,7,0,0,1,7-7,7,7,0,0,1,7,7,7,7,0,0,1-7,7A7,7,0,0,1-2833-2335Zm1.355,0a5.651,5.651,0,0,0,5.645,5.645,5.652,5.652,0,0,0,5.646-5.645,5.652,5.652,0,0,0-5.646-5.646A5.651,5.651,0,0,0-2831.645-2335Zm4.259,4v-.36c.744-.084.828-.144.828-1.008v-2.436c0-.779-.072-.828-.708-.923v-.337a11.105,11.105,0,0,0,2.029-.455v4.152c0,.84.059.912.828,1.008v.36Zm.636-7.164a.773.773,0,0,1,.768-.768.758.758,0,0,1,.768.768.766.766,0,0,1-.779.756A.777.777,0,0,1-2826.75-2338.164Z"
                    transform="translate(2833 2342)"
                    fill="#510d76"
                  />
                </svg>
                <Info
                  class="invisible group-hover:visible"
                  info_header="Private Entries"
                  info_text="This entry will not appear in your final Mentor X Log and your mentor will not see this log entry if this box is checked."
                />
              </div>
            </div>
            <div class="flex">
              <div class="pt-0.5">
                <input
                  name="hide"
                  type="checkbox"
                  class="mb-2 mr-2 rounded"
                  v-model="form_data.confidential"
                  id="isPrivate"
                />
              </div>
              <label for="isPrivate"
                >Keep this entry confidential from your mentor.</label
              >
            </div>
          </div>
        </div>
      </form>
      <div
        v-if="this.$route.params.id"
        class="col-span-3 lg:col-span-2 row-start-4 lg:row-start-1 row-span-1"
      >
        <div class="box-style">
          <entry-messages :id="this.$route.params.id" />
        </div>
      </div>
      <div class="row-start-5 lg:row-start-4 col-span-3 lg:col-span-2">
        <div class="flex justify-end py-5">
          <button
            aria-label="cancel_form"
            class="inline-block py-3 px-9 mx-3 bg-none text-purple border border-purple hover:bg-dark-purple rounded-full hover:text-white"
            @click="cancelForm"
          >
            Cancel
          </button>
          <button
            aria-label="submit_form"
            class="inline-block py-3 px-9 bg-purple hover:bg-dark-purple rounded-full text-white"
            @click="submitForm()"
          >
            <div v-if="errorMsgs.length !== 0 && showErrors">See Errors</div>
            <div v-else-if="submitted"><loading-spinner /></div>
            <div v-else>Submit</div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// TODO: If the form_type is ppdp_meeting, a check must be done to see if the ppdp form has been completed.
// If it has not, the user must be redirected to the ppdp form.
import { mapGetters } from "vuex";
import RenderField from "@/components/forms/RenderField";
import Info from "@/components/displays/tooltips/Info";
import { parseDateTime } from "@/composables/date_utils";
import { getUserName } from "@/composables/tokenUtils";
import LoadingSpinner from "@/components/reusable-components/LoadingSpinner";
import ErrorBanner from "@/components/forms/ErrorBanner";
import { parseDate } from "@/composables/date_utils";
import EntryMessages from "@/components/forms/EntryMessages";

export default {
  name: "StudentEntryForm",
  emits: ["submit", "cancel"],
  components: { ErrorBanner, LoadingSpinner, Info, RenderField, EntryMessages },
  props: {
    form_type: {
      type: String,
      required: true,
    },
    notes: {
      type: Object,
      required: false,
    },
    isUpdate: {
      type: String,
      required: true,
    },
    form_fields: {
      type: Array,
      required: true,
    },
    field_values: {
      type: Array,
      required: false,
    },
    is_private: {
      type: Boolean,
      required: false,
    },
    confidential: {
      type: Boolean,
      required: false,
    },
    submitted: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      wild_or_director: "",
      warning: true,
      form_data: {
        confidential: this.confidential,
      },
      border_present: ["time", "name", "attendance", "additional details"],
      modalOpen: this.modal_array,
      showErrors: false,
      errorMsgs: [],
    };
  },
  methods: {
    pushNewInput: function (input) {
      if (input.label) {
        this.form_data[input.label] = input;
      }
      this.validateData();
      // if (this.showErrors) {
      //
      // }
    },
    submitForm: function () {
      this.validateData();
      // console.log(this.form_data["Experience Type"].value)
      if (this.errorMsgs.length === 0) {
        let clean_data = { fields: [] };
        Object.entries(this.form_data).forEach((key) => {
          if (key[0].includes("Date of") || key[0].includes("Date and")) {
            clean_data["fieldwork_date"] = parseDateTime(key[1].value);
          } else if (key[0].toLowerCase().includes("description")) {
            clean_data["description"] = key[1].value;
          }
          if (key[1]["field_id"]) {
            clean_data["fields"].push(key[1]);
          } else {
            clean_data[key[0]] = key[1];
          }
        });
        // console.log(clean_data)
        // if (clean_data["Experience Type"]) {
        //   clean_data["Experience Type"].value =
        //     clean_data["Experience Type"].value[0].description;
        // }
        const userName = getUserName();
        clean_data[
          "description"
        ] = `${userName.firstname} ${userName.lastname}`;
        clean_data.fields = clean_data.fields.filter(
          // eslint-disable-next-line no-prototype-builtins
          (field) => !field.hasOwnProperty("isTrusted")
        );
        // clean_data.fields.forEach(
        //   (field) =>
        //     field.label === "Experience Type" && (field.value = field.value[0])
        // );
        // console.log(clean_data)
        this.$emit("submit", clean_data);
      }
    },
    cancelForm: function () {
      this.$emit("cancel");
    },
    setBorder: function (border) {
      if (border) {
        return "border-t border-cool-gray";
      } else return "";
    },
    toggleModal: function (index) {
      this.modalOpen[index].open = !this.modalOpen[index].open;
    },
    timeDiffCalc: function (datetime) {
      let timeDiffMilliseconds = Math.abs(Date.now() - datetime);
      const days = Math.floor(timeDiffMilliseconds / (1000 * 3600 * 24));
      const hours = Math.floor(
        (timeDiffMilliseconds % (1000 * 3600 * 24)) / (1000 * 3600)
      );
      const minutes = Math.floor(
        (timeDiffMilliseconds % (1000 * 3600)) / (1000 * 60)
      );
      return `${days} days, ${hours} hours, ${minutes} minutes`;
    },
    validateData: function () {
      this.errorMsgs = [];
      this.showErrors = false;
      this.form_fields.forEach((field) => {
        // console.log(field.name);
        // console.log("field:", field);
        // console.log("value:", this.form_data[field.name]);
        if (
          !field.parent_field_id &&
          field.name !== "Time" &&
          field.name !== "Networking Time" &&
          field.name !== "Orientation Time" &&
          field.name !== "Point of Contact Time"
        ) {
          const now = new Date();
          if (this.form_data[field.name]) {
            switch (typeof this.form_data[field.name].value) {
              case "string":
                if (this.form_data[field.name].value === "") {
                  this.errorMsgs.push("Missing value for " + field.name);
                } else if (
                  this.form_data[field.name].length &&
                  this.form_data[field.name].length < field.min_length
                ) {
                  this.errorMsgs.push(field.name + " needs more content.");
                } else if (
                  (field.name === "Date of Experience" ||
                    field.name === "Date of Debrief" ||
                    field.name === "Date of Contact" ||
                    field.name === "Date of Year-End Meeting" ||
                    field.name === "Date of Networking" ||
                    field.name === "Date of PPDP Meeting") &&
                  parseDate(this.form_data[field.name].value) > now
                ) {
                  this.errorMsgs.push(field.name + " cannot be in the future.");
                } else if (
                  (field.name ===
                    "All my minimum required fieldwork was completed, logged, and approved in advance of my year-end meeting with my mentor." ||
                    field.name ===
                      "I met face-to-face with my mentor (or I was given express, advance permission by a program director to communicate with my mentor in a different manner)." ||
                    field.name ===
                      "I provided my mentor with a copy of my Mentor X Log and discussed it with my mentor." ||
                    field.name ===
                      'I have not logged and received credit for activity that I am not allowed to receive credit for as described in the "Student Professional Responsibilities" section of the Mentor X Program Policy Manual.' ||
                    field.name ===
                      "All my Mentor X Log entries are true and correct.") &&
                  this.form_data[field.name].value === "No"
                ) {
                  this.errorMsgs.push(
                    'The activity: "' + field.name + '" must be completed.'
                  );
                } else if (
                  field.name ===
                    "The experience which I am logging is an allowed activity." &&
                  this.form_data[field.name].value === "No"
                ) {
                  this.errorMsgs.push(
                    "The experience must be an allowed activity."
                  );
                }
                break;
              case "object":
                if (
                  Array.isArray(this.form_data[field.name].value) &&
                  !this.form_data[field.name].value.length
                ) {
                  // Array
                  if (!this.form_data[field.name].value.length) {
                    this.errorMsgs.push("Missing value for " + field.name);
                  } else if (
                    (field.name === "Date of Experience" ||
                      field.name === "Date of Debrief" ||
                      field.name === "Date of Contact" ||
                      field.name === "Date of Year-End Meeting" ||
                      field.name === "Date of Networking" ||
                      field.name === "Date and Time") &&
                    parseDate(this.form_data[field.name].value) >
                      now
                  ) {
                    this.errorMsgs.push(
                      field.name + " cannot be in the future."
                    );
                  } else if (!this.form_data[field.name].value[0]) {
                    this.errorMsgs.push("Missing value for " + field.name);
                  }
                } else {
                  // Not an array
                  if (!this.form_data[field.name].value) {
                    this.errorMsgs.push("Missing value for " + field.name);
                  }
                }
                break;
              case "boolean":
                if (this.form_data[field.name].value === false) {
                  this.errorMsgs.push(field.name + " must be selected.");
                }
                break;
            }
          } else {
            this.errorMsgs.push("Missing value for " + field.name);
          }
        } else if (
          field.parent_field_id &&
          field.name === "Time Spent" &&
          this.form_data[field.name].value === 0
        ) {
          // console.log(field.name);
          // console.log("field:", field);
          // console.log("value:", this.form_data[field.name]);
          this.errorMsgs.push("Missing value for " + field.name);
        }

        if (this.form_data[field.name] !== undefined && (field.name.includes("Date of") || field.name.includes("Date and"))) {
          const fieldworkDate = parseDateTime(this.form_data[field.name].value).valueOf();
          if(!(fieldworkDate >= parseDateTime(this.lookup_current_term.start_date).valueOf() &&
              fieldworkDate <= parseDateTime(this.lookup_current_term.end_date).valueOf())) {
            this.errorMsgs.push(`Fieldwork must take place within the current term`)
          }
        }
      });
      if (this.errorMsgs.length) {
        this.showErrors = true;
      }
    },
  },
  computed: {
    ...mapGetters(["student_profile", "form_type_description", "lookup_current_term"]),
    values_map: function () {
      if (!this.field_values) {
        return;
      }
      let map = {};
      this.field_values.forEach((field) => {
        if (!map[field.field_id]) {
          map[field.field_id] = [field.field_value];
        } else {
          map[field.field_id].push(field.field_value);
        }
      });
      return map;
    },
  },
};
</script>

<style scoped></style>
